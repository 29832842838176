import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Enews from "../components/enews";
import MyLocation from "../components/myLocation";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import ReviewRotator from "../components/reviewRotator";
import FullLengthDoctorImage from "../components/fullLengthDoctorImage";
import prepareSchemaHours from "../helpers/prepareSchemaHours";

// import styles from "./index.module.scss";

import * as styleSheet from "./index.module.scss"


const IndexPage = () => {
	const size = useWindowSize();
	const [schemaData, setSchemaData] = useState(null);

	// Hook
	function useWindowSize() {
		// Initialize state with undefined width/height so server and client renders match
		// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
		const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
		});
		useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}

	const data = useStaticQuery(graphql`query IndexPageQuery {
  home: wpPage(isFrontPage: {eq: true}) {
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFHomePage {
      hero {
        images {
          mobileBackgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 699, placeholder: NONE, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          backgroundImageTablet: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 999, placeholder: NONE, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          backgroundImageDesktop: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
              publicURL
              extension
            }
          }
          backgroundImageXL: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
              publicURL
              extension
            }
          }
        }
        content {
          headingImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          blurb
          link {
            target
            title
            url
          }
          phoneNumber
        }
        callouts {
          callout1 {
            preHeading
            heading
            blurb
            link {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
                }
                publicURL
                extension
              }
            }
          }
          callout2 {
            heading
            imAPrefix
            links {
              link {
                target
                title
                url
              }
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
      intro {
        left {
          blurb
          heading
          fieldGroupName
          list {
            heading
            shortParagraph
            link {
              target
              title
              url
            }
          }
        }
      }
      insuranceLogoSection {
        ... on WpInsurance {
          id
          title
          ACFInsurance {
            logo {
              altText
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 90, layout: CONSTRAINED)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
      services {
        left {
          backgroundImageMobile: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 699, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          backgroundImageTablet: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 999, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          backgroundImageDesktop: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 595, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          backgroundImageXL: backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 850, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
          heading
          paragraphBlurb
          link {
            target
            title
            url
          }
        }
        selectedServices {
          ... on WpService {
            link
            title
            id
            ACFService {
              briefDescription
              serviceIcon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  publicURL
                  extension
                }
              }
            }
          }
        }
      }
      enewsAndLocations {
        form {
          heading
          blurb
        }
      }
      reviews {
        right {
          heading
          selectedReviews {
            ... on WpReview {
              id
              ACFReview {
                blurb
                location
                stars
              }
              title
            }
          }
        }
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  arrowGrayLocal: file(name: {eq: "arrow_gray_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  locationIcon: file(name: {eq: "location_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 14, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  redStar: file(name: {eq: "Star"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  quotes: file(name: {eq: "quotation_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 70, layout: CONSTRAINED)
    }
  }
  dentists: allWpDentist {
    nodes {
      ACFDentist {
				headshot {
					localFile {
						childImageSharp {
              gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
            }
					}
				}
        briefDescription
        fullLengthImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
      title
      link
    }
  }
  locations: allWpLocation(sort: {fields: title, order: ASC}) {
    nodes {
      id
      title
      link
      birdeyeLocation {
				businessId
				timezone
				location {
					address1
					address2
					city
					state
					zip
					lat
					lng
				}
				name
				phone
				hoursOfOperations {
					day
					isOpen
					workingHours {
						startHour
						endHour
					}
				}
			}
      wpDentists {
        ACFDentist {
					headshot {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 280, layout: CONSTRAINED)
							}
						}
					}
          briefDescription
          fullLengthImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
        title
        link
      }
      ACFLocation {
        bookMyAppointmentLink {
          url
          title
          target
        }
        menuTitle
        briefDescription
        locationInfo {
          left {
            dentists {
              ... on WpDentist {
                id
                ACFDentist {
									headshot {
										localFile {
											childImageSharp {
												gatsbyImageData(width: 280, layout: CONSTRAINED)
											}
										}
									}
                  briefDescription
                  fullLengthImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
                      }
                      publicURL
                      extension
                    }
                  }
                }
                title
                link
              }
            }
          }
        }
      }
    }
  }
}
`);

	const {
		home,
		home: {
			ACFHomePage: {
				hero: {
					images: {
						mobileBackgroundImage: heroBGMobile,
						backgroundImageTablet: heroBGTablet,
						backgroundImageDesktop: heroBGDesktop,
						backgroundImageXL: heroBGXL,
					},
					content: {
						headingImage,
						blurb,
						link,
						phoneNumber
					},
					callouts: {
						callout1: {
							preHeading: c1preHeading,
							heading: c1heading,
							blurb: c1blurb,
							link: c1link,
							backgroundImage: c1backgroundImage
						},
						callout2: {
							heading: c2heading,
							imAPrefix: c2imAPrefix,
							links: c2links,
							backgroundImage: c2backgroundImage
						}
					}
				},
				intro: {
					left: {
						blurb: introLeftBlurb,
						heading: introLeftHeading,
						list: introLeftList
					},
				},
				insuranceLogoSection,
				services: {
					left: {
					  backgroundImageMobile: servicesBackgroundImageMobile, 
					  backgroundImageTablet: servicesBackgroundImageTablet,
					  backgroundImageDesktop: servicesBackgroundImageDesktop,
					  backgroundImageXL: servicesBackgroundImageXL,
					  heading: servicesHeading,
					  paragraphBlurb: servicesParagraph,
					  link: servicesLink 
				  	},
					selectedServices
				},
				enewsAndLocations: {
					form: {
					  heading: enewsHeading,
					  blurb: enewsBlurb
					}
				},
				reviews: {
					right: {
						heading: reviewsHeading,
						selectedReviews
					},
					backgroundImage: reviewsBackgroundImage 
				}
			},
		},
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo
					}
				}
			}
		},
		rightArrowYellow,
		rightArrowYellowOffsite,
		rightArrowBlue,
		arrowRedLocal,
		arrowGrayLocal,
		arrowBlue,
		locationIcon,
		redStar,
		quotes,
		dentists: {
			nodes: dentistNodes
		},
		locations: {
			nodes: locationNodes
		},
	} = data;

	useEffect(() => {
		if (locationNodes.length < 1) {
			return;
		}

		const chenalLocation = locationNodes.find(location => location.title.includes('Chenal'));
		const nonChenalLocations = locationNodes.filter(location => !location.title.includes('Chenal'));

		if (!chenalLocation) {
			return;
		}

		const deparments = nonChenalLocations.map(location => {
			const deptHours = prepareSchemaHours(location.birdeyeLocation.hoursOfOperations);
			return `{
				"@context": "https://schema.org",
				"@type": "Dentist",
				"name": "${location.birdeyeLocation.name}",
				"url": "${location.link}",
				"telephone": "${location.birdeyeLocation.phone}",
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "${location.birdeyeLocation.location.address1} ${location.birdeyeLocation.location.address2}",
					"addressLocality": "${location.birdeyeLocation.location.city}",
					"addressRegion": "${location.birdeyeLocation.location.state}",
					"postalCode": "${location.birdeyeLocation.location.zip}",
					"addressCountry": "US"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": ${location.birdeyeLocation.location.lat},
					"longitude": ${location.birdeyeLocation.location.lng}
				},
				"openingHours": [${deptHours}]
			}`;
		});

		const chenalLocHours = prepareSchemaHours(chenalLocation.birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${chenalLocation.birdeyeLocation.name}",
			"url": "${chenalLocation.link}",
			"telephone": "${chenalLocation.birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${chenalLocation.birdeyeLocation.location.address1} ${chenalLocation.birdeyeLocation.location.address2}",
				"addressLocality": "${chenalLocation.birdeyeLocation.location.city}",
				"addressRegion": "${chenalLocation.birdeyeLocation.location.state}",
				"postalCode": "${chenalLocation.birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${chenalLocation.birdeyeLocation.location.lat},
				"longitude": ${chenalLocation.birdeyeLocation.location.lng}
			},
			"openingHours": [${chenalLocHours}],
			"department": [${deparments}]
		}`;

		setSchemaData(newSchema);
	}, []);

	return (
    <Layout>
			<Seo post={home} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<section className={styleSheet.hero}>
				<div className={styleSheet.top}>
					<div className={styleSheet.herobgcolor}></div>
					{ heroBGMobile && <FluidImage image={heroBGMobile} passedClass={styleSheet.mobilebg} loading="eager" /> }
					{ heroBGTablet && <FluidImage image={heroBGTablet} passedClass={styleSheet.bgtablet} loading="eager" /> }
					{ heroBGDesktop && <FluidImage image={heroBGDesktop} passedClass={styleSheet.bgdesktop} loading="eager" /> }
					{ heroBGXL && <FluidImage image={heroBGXL} passedClass={styleSheet.bgxl} loading="eager" /> }
          <div className={styleSheet.content}>
            <h1 className={styleSheet.screenreader}>Rock Family Dental</h1>
						{ headerLogo && <FluidImage image={headerLogo} passedClass={styleSheet.headerlogo} loading={"eager"} /> }
						{ headingImage && <FluidImage image={headingImage} loading={"eager"} /> }
						{ blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: blurb }}></div> }
						{ link && <WPLink link={link} passedClass={styleSheet.link} icon={rightArrowYellow} /> } 
						{ phoneNumber && <a className={styleSheet.phone} href={`tel:${phoneNumber}`}>{phoneNumber} <GatsbyImage
              image={rightArrowYellowOffsite.childImageSharp.gatsbyImageData}
              className={styleSheet.icon}
							placeholder="none"
							alt='' /></a>
						}
					</div>
				</div>
				<div className={styleSheet.bottom}>
					<div className={styleSheet.callout1}  data-sal={size.width < 700 ? 'none' : 'slide-up'} data-sal-delay={size.width < 700 ? '600' : '1200'} data-sal-easing="ease-in">
						{ c1preHeading && (
							<div className={styleSheet.preheadcontainer}>
								<span className={styleSheet.preheading}>{c1preHeading}</span> 
							</div>
						)}
						{ c1heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c1heading}}></div> }
						{ c1blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: c1blurb}}></div> }
						{ c1link && <WPLink link={c1link} passedClass={styleSheet.link} /> }
						{ c1backgroundImage && <FluidImage image={c1backgroundImage} passedClass={styleSheet.bg} loading={"eager"} /> }
					</div>
					<div className={styleSheet.callout2} data-sal="slide-up" data-sal-delay="800" data-sal-easing="ease-in">
						{ c2backgroundImage && <FluidImage image={c2backgroundImage} passedClass={styleSheet.bg} loading={"eager"} /> }	
						{ c2heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c2heading}}></div> }
						{ c2imAPrefix && <span className={styleSheet.prefix}>{c2imAPrefix}</span> }
						{ c2links && c2links.map( (link, index) => {
							return <WPLink key={`ctalinks-${index}`} link={link.link} passedClass={styleSheet.link} icon={rightArrowBlue} />;
						})}
					</div>
				</div>
			</section>

			<section className={styleSheet.intro}>
				<div className={styleSheet.left}>
					{ introLeftHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introLeftHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ introLeftBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introLeftBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introLeftList && (
						<ul className={styleSheet.list}>
							{ introLeftList.map( (listitem, _index) => {
								return (
									<li key={listitem.heading} className={styleSheet.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<FixedImage image={rightArrowBlue} passedClass={styleSheet.icon} />
										{ listitem.heading && <span className={styleSheet.listheading} dangerouslySetInnerHTML={{__html: listitem.heading }}></span> }
										{ listitem.shortParagraph && <p className={styleSheet.paragraph} dangerouslySetInnerHTML={{__html: listitem.shortParagraph}}></p> }
										{ listitem.link && <WPLink link={listitem.link} passedClass={styleSheet.link} icon={rightArrowYellowOffsite} passedIconClass={styleSheet.linkicon} /> }
									</li>
								)
							})}
						</ul>
					)}
				</div>
				<FullLengthDoctorImage locations={locationNodes} dentists={dentistNodes} />
			</section>

			{ insuranceLogoSection && (
				<section className={styleSheet.insurance} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" >
					{ insuranceLogoSection.map( (insurance, _index) => {
						return <FluidImage key={`insurancelogo-${insurance.ACFInsurance.logo.id}`} passedClass={styleSheet.logo} image={insurance.ACFInsurance.logo} />;
					})}
				</section> 
			)}

			<section className={styleSheet.services}>
				<div className={styleSheet.left}>
					{ servicesBackgroundImageMobile && <FluidImage image={servicesBackgroundImageMobile} passedClass={styleSheet.bgmobile} /> }
					{ servicesBackgroundImageTablet && <FluidImage image={servicesBackgroundImageTablet} passedClass={styleSheet.bgtablet} /> }
					{ servicesBackgroundImageDesktop && <FluidImage image={servicesBackgroundImageDesktop} passedClass={styleSheet.bgdesktop} /> }
					{ servicesBackgroundImageXL && <FluidImage image={servicesBackgroundImageXL} passedClass={styleSheet.bgxl} /> }

					{ servicesHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: servicesHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ servicesParagraph && <p dangerouslySetInnerHTML={{__html: servicesParagraph}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }
					{ servicesLink && <WPLink link={servicesLink} passedClass={styleSheet.link} icon={rightArrowYellow} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />}
				</div>
				<div className={styleSheet.right}>
					{ selectedServices && (
						<div className={styleSheet.servicescontainer}>
							{ selectedServices && selectedServices.map((service, _index) => {


								return (
									<div key={`ftsrvc-'${service.id}`} className={styleSheet.featuredservice} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										{service.ACFService.serviceIcon && (
											<div className={styleSheet.iconrow}>
												<div className={styleSheet.iconcontainer}>
													<FluidImage image={service.ACFService.serviceIcon} passedClass={styleSheet.icon} /> 
												</div>
											</div>
										)}
										<Link to={service.link} className={styleSheet.title}><span dangerouslySetInnerHTML={{__html: service.title}}></span><FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /> </Link>
										{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
									</div>
								)
							})}
						</div>
					)}
					{ servicesLink && <WPLink link={servicesLink} passedClass={styleSheet.link} icon={rightArrowYellow} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />}
				</div>
			</section>
			<section className={styleSheet.enewslocations}>
				<div className={styleSheet.form}>
					{ enewsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: enewsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ enewsBlurb && <p dangerouslySetInnerHTML={{__html: enewsBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }
					<Enews data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
				</div>
				<div className={styleSheet.location}>
					<div className={styleSheet.left}>
						<MyLocation locations={locationNodes} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} locationIcon={locationIcon} rightArrowYellowOffsite={rightArrowYellowOffsite} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
					</div>
					<div className={styleSheet.right}>
						<span className={styleSheet.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">Find more locations.</span>
						<ul className={styleSheet.list}>
							{ locationNodes && locationNodes.map((location, index) => {
								return (
									<li key={`lstloc-'${location.id}`} className={styleSheet.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<Link to={location.link} className={styleSheet.link}>
											<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
											<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
										</Link>	
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</section>
			<section className={styleSheet.reviews}>
				{ reviewsBackgroundImage && <FluidImage image={reviewsBackgroundImage} passedClass={styleSheet.bg} /> }
				<div className={styleSheet.inner}>
					{ reviewsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: reviewsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ selectedReviews && <ReviewRotator reviews={selectedReviews} redStar={redStar} quotes={quotes} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
				</div>
			</section>
		</Layout>
    );
};

export default IndexPage;
