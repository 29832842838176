// extracted by mini-css-extract-plugin
export var LocationCard = "index-module--LocationCard--VUSiO";
export var LocationCardTeamPage = "index-module--LocationCardTeamPage--2jgwD";
export var address = "index-module--address--TdymP";
export var bottom = "index-module--bottom--gk3b+";
export var directions = "index-module--directions--Zxk35";
export var icon = "index-module--icon--HnIUf";
export var iconhover = "index-module--iconhover--SIrAv";
export var link = "index-module--link--2xdh3";
export var linkappointments = "index-module--linkappointments--aIpBW";
export var linkhover = "index-module--linkhover--5S9YS";
export var phone = "index-module--phone--donTi";
export var screenreader = "index-module--screenreader--0EnYI";
export var title = "index-module--title--lyYz8";
export var titleappointments = "index-module--titleappointments--u6ZCm";
export var top = "index-module--top--I0est";