// extracted by mini-css-extract-plugin
export var ReviewRotator = "index-module--ReviewRotator--r4G-q";
export var blurb = "index-module--blurb--uwdag";
export var content = "index-module--content--SiDRi";
export var linkhover = "index-module--linkhover--boy9O";
export var location = "index-module--location--ye5dv";
export var name = "index-module--name---xD3J";
export var quotes = "index-module--quotes--1TFpM";
export var rating = "index-module--rating--LE1s2";
export var review = "index-module--review--pgFa6";
export var screenreader = "index-module--screenreader--FBohA";
export var star = "index-module--star--z-ys4";